import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { PrimeModule } from '../../../modules/primeng.module';

@Component({
  selector: 'app-g2g-text-input',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    PrimeModule
  ],
  templateUrl: './g2g-text-input.component.html',
  styleUrl: './g2g-text-input.component.scss'
})
export class G2gTextInputComponent {

  @Input('formname') form!: FormGroup;
  @Input('type') type: string = 'text';
  @Input('classes') classes: string = 'w-100';
  @Input('placeholder') placeholder: string = 'Enter Placeholder';
  @Input('formcontrolname') formcontrolname: string = '';
  @Output('enterkey') enterkey: EventEmitter<any> = new EventEmitter<any>();

  hasError(formcontrolname: string, validation: string) {
    if (this.form && formcontrolname) {
      return this.form.get(formcontrolname)?.hasError(validation) && this.form.get(formcontrolname)?.touched;
    } else {
      return false;
    }
  }

  enterPressed(event: KeyboardEvent) {
    if (event.code == "Enter") {
      this.enterkey.emit(event);
    }
  }

}
